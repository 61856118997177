<template>
  <div class="exchangeSearch">
    <van-nav-bar left-arrow
                 @click-left="$router.back(-1)"
                 :border="false">
      <template #title>
        <van-search v-model="searchValue"
                    placeholder="搜索商品名称"
                    background="none"
                    ref="search"
                    :autofocus="true"
                    :clearable="false"
                    @search="searchFn($event)" />
      </template>
      <template #right>
        <van-button type="primary"
                    color="#fc7301"
                    @click="searchFn($event)">搜索</van-button>
      </template>
    </van-nav-bar>
    <div>
      <div class="searchHistoryTitle">
        <span>搜索历史</span>
        <span @click="deleteAllFn">
          <span class="ml18 c-FE6507 icon iconfont iconshanchulaji-xianxing mr6 fs14"></span>
        </span>
      </div>
      <div class="remark"
           v-if="historyList.length===0">暂无搜索历史</div>
      <div class="historyList">
        <div v-for="(item,index) in historyList"
             :key="index"
             @click.prevent="searchFn($event,item)">
          <span class="name">{{item}}</span>
          <span class="clearIcon"
                @click.stop="deleteFn(index)">
            <span class="icon iconfont iconxianshi_quxiao"></span>
          </span>
        </div>
      </div>
    </div>
    <van-popup v-model:show="clearPopupShow"
               class="clearPopup">
      <div class="clearBox">
        <div class="clearTitle">清空记录</div>
        <div class="clearMsg">是否清空搜索记录</div>
        <div class="clearBtn">
          <van-button color="#ccc"
                      @click="cancelPopup">取消</van-button>
          <van-button color="#fc7301"
                      @click="confirmClearFn">清除</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs } from 'vue'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    setup() {
      const $router = useRouter();
      const state = reactive({
        searchValue: '',
        historyList: localStorage.getItem('historyList') ? JSON.parse(localStorage.getItem('historyList')) : [],
        clearPopupShow: false
      });
      const deleteAllFn = () => {
        state.clearPopupShow = true;
      };
      const searchFn = (e, item) => {
        if (item) {
          $router.push({
            path: '/exchangeshop/exchangesearch',
            query: {
              text: item
            }
          })
        } else {
          if (state.searchValue.trim()) {
            state.historyList.unshift(state.searchValue);
            state.historyList = state.historyList.slice(0, 10);
            localStorage.setItem('historyList', JSON.stringify(state.historyList));
            $router.push({
              path: '/exchangeshop/exchangesearch',
              query: {
                text: state.searchValue
              }
            })
          }
        }
      };
      const deleteFn = (index) => {
        state.historyList.splice(index, 1);
        localStorage.setItem('historyList', JSON.stringify(state.historyList));
      };
      const cancelPopup = () => {
        state.clearPopupShow = false;
      };
      const confirmClearFn = () => {
        localStorage.removeItem('historyList');
        state.historyList = [];
        state.clearPopupShow = false;
      };

      return {
        cancelPopup,
        deleteAllFn,
        searchFn,
        deleteFn,
        confirmClearFn,
        ...toRefs(state)
      }
    }
  })
</script>

<style lang="scss" scoped>
  .exchangeSearch {
    background: #fff;
    height: 100%;
    .van-nav-bar {
      .van-button {
        width: 66px;
        height: 32px;
        border-radius: 4px;
        border: none;
        font-size: 14px;
      }
      ::v-deep(.van-nav-bar__left) {
        left: -10px;
        top: -5px;
        .van-icon {
          font-size: 22px;
          color: #333;
          padding-top: 4px;
        }
      }
      ::v-deep(.van-nav-bar__right) {
        padding: 0 10px;
      }
      ::v-deep(.van-nav-bar__title) {
        width: 273px;
        margin: 0 30px;
        max-width: 100%;
        .van-search {
          height: 30px;
          width: 273px;
          .van-search__content {
            background: #f2f2f2;
            border-radius: 8px;
            width: 273px;
          }
          .van-icon {
            color: #333;
            padding-top: 2px;
          }
        }
      }
    }
    .searchHistoryTitle {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px 0px 10px;
      span {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }
    }
    .remark {
      text-align: center;
      color: #e0e0e0;
      font-size: 16px;
      margin-top: 39px;
    }
    .historyList {
      display: flex;
      flex-wrap: wrap;
      div {
        margin-top: 15px;
      }
      padding: 0px 10px;
      .name {
        background: #f6f6f6;
        border-radius: 14px;
        padding: 4px 10px;
        font-size: 14px;
        color: #7d7d7d;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        min-width: 24px;
        text-align: center;
        max-width: 60px;
      }
      .clearIcon {
        color: #d8d8d8;
        position: relative;
        top: -15px;
        left: -10px;
        .van-icon {
          font-size: 16px;
        }
      }
    }
    ::v-deep(.clearPopup) {
      border-radius: 10px;
    }
    .clearBox {
      width: 290px;
      height: 185px;
      color: #333;
      .clearTitle,
      .clearMsg {
        text-align: center;
      }
      .clearTitle {
        font-size: 19px;
        font-weight: 600;
        padding-top: 24px;
      }
      .clearMsg {
        color: #666;
        padding-top: 20px;
        font-size: 16px;
      }
      .clearBtn .van-button {
        width: 110px;
        height: 43px;
        margin: 35px 17px 0px;
        font-size: 16px;
      }
    }
  }
</style>